// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-index-js": () => import("./../../src/pages/article/index.js" /* webpackChunkName: "component---src-pages-article-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-article-js": () => import("./../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-photo-page-js": () => import("./../../src/templates/photo-page.js" /* webpackChunkName: "component---src-templates-photo-page-js" */),
  "component---src-templates-tags-js": () => import("./../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-video-page-js": () => import("./../../src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */)
}

